// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-application-form-js": () => import("./../../../src/pages/application-form.js" /* webpackChunkName: "component---src-pages-application-form-js" */),
  "component---src-pages-appointment-confirmation-js": () => import("./../../../src/pages/appointment-confirmation.js" /* webpackChunkName: "component---src-pages-appointment-confirmation-js" */),
  "component---src-pages-eligibility-form-js": () => import("./../../../src/pages/eligibility-form.js" /* webpackChunkName: "component---src-pages-eligibility-form-js" */),
  "component---src-pages-eligibility-results-js": () => import("./../../../src/pages/eligibility-results.js" /* webpackChunkName: "component---src-pages-eligibility-results-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-schedule-appointment-js": () => import("./../../../src/pages/schedule-appointment.js" /* webpackChunkName: "component---src-pages-schedule-appointment-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-tags-index-js": () => import("./../../../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-pages-terms-conditions-js": () => import("./../../../src/pages/terms-conditions.js" /* webpackChunkName: "component---src-pages-terms-conditions-js" */),
  "component---src-templates-categories-js": () => import("./../../../src/templates/categories.js" /* webpackChunkName: "component---src-templates-categories-js" */),
  "component---src-templates-home-page-js": () => import("./../../../src/templates/home-page.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-location-page-js": () => import("./../../../src/templates/location-page.js" /* webpackChunkName: "component---src-templates-location-page-js" */),
  "component---src-templates-news-detail-page-js": () => import("./../../../src/templates/news-detail-page.js" /* webpackChunkName: "component---src-templates-news-detail-page-js" */),
  "component---src-templates-news-page-js": () => import("./../../../src/templates/news-page.js" /* webpackChunkName: "component---src-templates-news-page-js" */),
  "component---src-templates-organization-home-js": () => import("./../../../src/templates/organization-home.js" /* webpackChunkName: "component---src-templates-organization-home-js" */),
  "component---src-templates-resource-page-js": () => import("./../../../src/templates/resource-page.js" /* webpackChunkName: "component---src-templates-resource-page-js" */),
  "component---src-templates-signup-form-js": () => import("./../../../src/templates/signup-form.js" /* webpackChunkName: "component---src-templates-signup-form-js" */),
  "component---src-templates-submenu-js": () => import("./../../../src/templates/submenu.js" /* webpackChunkName: "component---src-templates-submenu-js" */)
}

